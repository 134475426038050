import React, { useEffect, useState, useRef } from 'react';
import './SpinWheel.scss';
import { callAPI } from '../../services/apiRequest';
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup';
import prize from '../../assets/img/spinwheel/prize.png';

//test prize img
import prize0 from '../../assets/img/spinwheel/1.png';
import prize1 from '../../assets/img/spinwheel/2.png';
import prize2 from '../../assets/img/spinwheel/3.png';
import prize3 from '../../assets/img/spinwheel/4.png';
import prize4 from '../../assets/img/spinwheel/5.png';
import prize5 from '../../assets/img/spinwheel/6.png';

const SpinWheel = ({ config, gameId, identityNumber }) => {
  const [numberOfRoll, setNumberOfRoll] = useState(0);
  const [wheelSpinning, setWheelSpinning] = useState(false);
  const [reward, setReward] = useState(null);
  const wheelRef = useRef(null);
  const [error, setError] = useState(null);
  const [popup, setPopup] = useState(null);

  //test prize img
  const prizeImages = [prize0, prize1, prize2, prize3, prize4, prize5];

  const convertKeysToLowercase = (array) => {
    return array.map((item, index) => {
      let newItem = {};
      Object.keys(item).forEach((key) => {
        const newKey = key.charAt(0).toLowerCase() + key.slice(1);
        newItem[newKey] = item[key];
      });

      //test prize img
      newItem.image = prizeImages[index % prizeImages.length];

      return newItem;
    });
  };

  useEffect(() => {
    if (config && Object.keys(config).length > 0) {
      // console.log(config);
      const result = config;
      let configParam = {};
      try {
        if (result.ConfigParam) {
          configParam = JSON.parse(result.ConfigParam);
        }
      } catch (error) {
        console.error('Error parsing ConfigParam:', error);
      }

      let wheelConfig = {};
      try {
        wheelConfig = configParam.InterfaceConfig || {};
        console.log(wheelConfig);
      } catch (error) {
        console.error('Error parsing InterfaceConfig:', error);
      }

      console.log(convertKeysToLowercase(wheelConfig.Segments));

      setNumberOfRoll(result.TotalPerUserCount);

      const wheelImage = new Image();
      wheelImage.src = prize;

      wheelImage.onload = () => {
        const theWheel = new window.Winwheel({
          canvasId: 'canvas',
          segments: convertKeysToLowercase(wheelConfig?.Segments) || [],
          // drawMode: 'image',
          // wheelImage: wheelImage,
          drawMode: 'segmentImage',
          imageDirection: 'N',
          numSegments: (wheelConfig?.Segments || []).length,
          animation: {
            type: 'spinOngoing',
            duration: 5,
            spins: 15,
            callbackFinished: alertPrize,
          },
          responsive: true,
          // strokeStyle: 'white',
          // lineWidth: 2,
          strokeStyle: 'transparent',
          lineWidth: 0,
          innerRadius: 10,
          outerRadius: 150,
          textFillStyle: 'white',
          textStrokeStyle: 'white',
          textFontSize: 20,
          textFontWeight: 400,
        });

        wheelRef.current = theWheel;
        theWheel.draw();
      };

      wheelImage.onerror = () => {
        console.error('Error loading the wheel image');
      };
    }
  }, [config]);

  const startSpin = () => {
    if (numberOfRoll === 0 || wheelSpinning) return;

    setWheelSpinning(true);

    if (wheelRef.current) {
      wheelRef.current.stopAnimation(false);
      wheelRef.current.rotationAngle = 0;
      wheelRef.current.draw();

      wheelRef.current.animation = {
        type: 'spinOngoing',
        duration: 5,
        spins: 15,
      };
      wheelRef.current.startAnimation();
    }

    callAPI(process.env.REACT_APP_BP_URL, '/bc/get/gamereward', {
      gameId: gameId,
      identityNumber: identityNumber,
    })
      .then((res) => {
        setReward(res);

        if (wheelRef.current) {
          const segments = wheelRef.current.segments.filter(Boolean);
          const segmentIndex = segments.findIndex(
            (segment) => segment.prizeId === String(res.GamePrizeId)
          );

          if (segmentIndex !== -1) {
            const segmentCount = segments.length;
            const segmentSize = 360 / segmentCount;

            const stopAngle = segmentIndex * segmentSize + segmentSize / 2;

            // console.log(stopAngle);

            wheelRef.current.stopAnimation(false);
            wheelRef.current.rotationAngle =
              wheelRef.current.rotationAngle % 360;
            wheelRef.current.draw();

            wheelRef.current.animation = {
              type: 'spinToStop',
              duration: 3,
              spins: 2,
              stopAngle: stopAngle,
              callbackFinished: () => {
                alertPrize();
                setWheelSpinning(false);
              },
            };

            wheelRef.current.startAnimation();
          } else {
            console.error(
              'Segment not found for GamePrizeId:',
              res.GamePrizeId
            );
            setError('Failed to find the corresponding segment.');
            setWheelSpinning(false);
          }
        }

        setError(null);
      })
      .catch((error) => {
        console.error('Error during API call:', error);
        setError(error.response.data.ErrorDesc || 'Failed to get reward');

        // Stop the wheel on failure
        if (wheelRef.current) {
          wheelRef.current.stopAnimation(false);
          setWheelSpinning(false);
        }
      });
  };

  const resetWheel = () => {
    if (wheelRef.current) {
      wheelRef.current.stopAnimation(false);
      wheelRef.current.rotationAngle = 0;
      wheelRef.current.draw();
    }
    setWheelSpinning(false);
  };

  const alertPrize = () => {
    const prizeName = reward ? reward.Name : 'No reward';
    setPopup(prizeName);

    resetWheel();
    setNumberOfRoll((prev) => prev - 1);
  };

  useEffect(() => {
    if (wheelRef.current && reward) {
      wheelRef.current.animation.callbackFinished = alertPrize;
    }
  }, [reward]);

  return (
    <div className="wrapper-container">
      <div id="main-container">
        <h2 className="game-title">Lucky spin</h2>
        <div className="the_wheel">
          <canvas
            id="canvas"
            width="350"
            height="350"
            data-responsiveminwidth="200"
            data-responsivescaleheight="true"
            data-responsivemargin="100"
          ></canvas>
          <div className="spin" id="spin_button" onClick={startSpin}>
            <div className="inner-spin"></div>
            <p className="text-spin">Spin</p>
          </div>
        </div>
      </div>
      <div className="popup-lucky"></div>
      <div className="container-number-of-spin">
        <span className="number-of-spin-title">You have </span>
        <span className="number-of-spin-quality">
          {numberOfRoll || numberOfRoll === 0 ? numberOfRoll : '-'}
        </span>
        <span className="number-of-spin-title"> play times</span>
      </div>
      {error && (
        <ErrorPopup
          message={error}
          onClose={() => setError(null)}
          header="Error"
          btnText="Close"
          title=""
        />
      )}
      {popup && (
        <ErrorPopup
          message={popup}
          onClose={() => setPopup(null)}
          header="Congratulation"
          btnText="Collect"
          title="You got"
        />
      )}
    </div>
  );
};

export default SpinWheel;
