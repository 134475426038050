import axios from 'axios';

// fake token
// const TOKEN =
//   'eyJhbGciOiJBMTI4S1ciLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwidHlwIjoiSldUIiwiY3R5IjoiSldUIn0._Ghmc4lNhiBhOFDiJT4LMpt7TW6sF9ue6bXTnZCHThy7Z0UhoRViyg.97jbWqi2kks9g7NSsa1TWA.MEPKlJNAe-eP6nsl4TpP-Qk4oFYMjpDATp4Q5C2GFZJ7hzlo-_JwZfvyl_qHvfpcCn2rFErqqo_cRfjK49Tnmp2LYEpS_Wl5ABYpRjvSftDd10kM_O3SsIfUb9prEhs090oylJNXQBtq61JnEW2M6QIzLMfOchLnkXWmyWgfzQMtDVv7p6QpONPVir6A740s6okgdCvWGiKM-OgQ1iB2ZYlIYYl3DsZr0523SHz846dc0ZMAkVxRHTk1JIi-yTBcdA6Qbzi-_uHVKZ-U1S_P2i7a7-U_j5UY0B-2roXP1JNWpOYJDXHg-pwQigvCRRxKO5NXHo4Nrd_vLw1tqDxFClhA43768ip6EqrNSExRVCjP70QEt1V76-Oz_i-XQQRJ1_EFwm2B1id1kuhCI0OmCGMugeoEAthjt6mZLPAQmKMn5RnopPZbT8O9y7lY9GuchQS38LM6klIHCVX8rcSmERR4yt3AFaO-AVb2_qRCPhQ.fWK6XurFzPsRCndTHhAXSQ';

export const callAPI = async (domain, endpoint, body) => {
  try {
    const response = await axios.post(`${domain}${endpoint}`, body);
    const allData = response.data;
    // console.log(allData);
    return allData;
  } catch (error) {
    throw error;
  }
};
