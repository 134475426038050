import React, { useReducer, useState } from 'react';
import './LuckyBox.scss';
import Confetti from '../../components/Confetti/Confetti';
import ErrorPopup from '../../components/ErrorPopup/ErrorPopup'; // Import popup
import box from '../../assets/img/luckybox/box.png';
import boxLid from '../../assets/img/luckybox/box-lid.png';
import { callAPI } from '../../services/apiRequest'; // Import the API call service

// test img
import kuku50 from '../../assets/img/prize/coin.png';
import kuku20 from '../../assets/img/prize/coin.png';
import coin from '../../assets/img/prize/coin.png';
import badluck from '../../assets/img/prize/badluck.png';

const LuckyBox = ({ config, gameId, identityNumber }) => {
  const [numberOfRoll, setNumberOfRoll] = useState(config.TotalPerUserCount || 10);
  const init_state = {
    move: 'move',
    jump: '',
    rotated: '',
    rotating: '',
    currentAsset: kuku50, // default asset
  };

  const [state, setState] = useReducer(
    (state, new_state) => ({
      ...state,
      ...new_state,
    }),
    init_state
  );

  const { move, rotating, rotated, jump, currentAsset } = state;
  const [popup, setPopup] = useState(null);
  const [error, setError] = useState(null);

  function getRandomAsset() {
    const assets = [kuku50, kuku20, coin, badluck];
    return assets[Math.floor(Math.random() * assets.length)];
  }

  function animate() {
    let isDone = rotated === 'rotated';

    if (!isDone) {
      setState({ rotating: 'rotating' });
      setTimeout(() => {
        setState({ rotated: 'rotated' });
      }, 100);

      callAPI(process.env.REACT_APP_BP_URL, '/bc/get/gamereward', {
        gameId: gameId,
        identityNumber: identityNumber,
      })
        .then((res) => {
          setNumberOfRoll((numberOfRoll) => numberOfRoll - 1);
          const rewardName = res?.Name || 'No reward';
          setTimeout(() => {
            setState({ jump: 'jump' });
          }, 300);

          setTimeout(() => {
            setPopup(rewardName);
            setState({ currentAsset: getRandomAsset() });
            setError(null);
          }, 3000);
        })
        .catch((err) => {
          setError(err.response?.data?.ErrorDesc || 'Failed to get reward');
          setState(init_state);
        });
    } else {
      setState(init_state);
    }

    let moving = move === 'move' ? '' : 'move';
    setState({ move: moving });
  }

  function handlePopupClose() {
    setState(init_state);
    setPopup(null);
    setError(null);
  }

  return (
    <div className="App">
      <h2 className="game-title">Lucky box</h2>
      <Confetti open={jump === 'jump'} />
      <div className="img-container">
        <img className={`kuku ${jump}`} src={currentAsset} alt="prize" />
        <button className="box" onClick={() => animate()}>
          <img src={box} alt="box" />
        </button>
        <img
          className={`lid ${move} ${rotating} ${rotated}`}
          src={boxLid}
          alt="box-lid"
        />
      </div>

      <div className="container-number-of-spin">
        <span className="number-of-spin-title">You have </span>
        <span className="number-of-spin-quality">
          {numberOfRoll || numberOfRoll === 0 ? numberOfRoll : '-'}
        </span>
        <span className="number-of-spin-title"> play times</span>
      </div>

      {popup && (
        <ErrorPopup
          message={popup}
          onClose={handlePopupClose}
          header="Congratulation"
          btnText="Collect"
          title="You got"
        />
      )}

      {error && (
        <ErrorPopup
          message={error}
          onClose={handlePopupClose}
          header="Error"
          btnText="Close"
          title=""
        />
      )}
    </div>
  );
};

export default LuckyBox;
