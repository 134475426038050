import React from 'react';
import './ErrorPopup.scss';

const ErrorPopup = ({ message, onClose, header, btnText, title }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">{header}</div>
        <div className="content-wrapper">
          <h2>{title}</h2>
          <p>{message}</p>
        </div>
        <button onClick={onClose}>{btnText}</button>
      </div>
    </div>
  );
};

export default ErrorPopup;
