import './App.css';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SpinWheel from './pages/SpinWheel/SpinWheel';
import { callAPI } from './services/apiRequest';
import React, { useEffect, useState, useCallback } from 'react';
import ErrorPopup from './components/ErrorPopup/ErrorPopup';
import LuckyBox from './pages/LuckyBox/LuckyBox';

function App() {
  const [config, setConfig] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || '');

  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const gameId = params.get('gameId') || '';
  const identityNumber = params.get('identityNumber') || '';
  const gameType = params.get('gameType') || 'SPIN';
  const type = params.get('type') || 'webview';
  const tokenWebView = params.get('token') || '';

  const componentMap = {
    SPIN: SpinWheel,
    BOX: LuckyBox,
  };

  const fetchNewData = useCallback(() => {
    setLoading(true);

    callAPI(process.env.REACT_APP_BP_URL, '/bc/get/gameconfig', {
      gameId: gameId,
      identityNumber: identityNumber,
    })
      .then((res) => {
        setLoading(false);
        setConfig(res);
        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        setError(
          error.response.data.ErrorDesc || 'Failed to get game configuration'
        );
      });
  }, [gameId, identityNumber]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.token) {
        setToken(event.data.token);
        localStorage.setItem('token', event.data.token);
      }
    };
    if(type !== 'webview'){
      window.addEventListener('message', handleMessage);
    }else{
      setToken(tokenWebView);
      localStorage.setItem('token', tokenWebView);
    }
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    fetchNewData();
  }, [fetchNewData]);

  return (
    <div className="App">
      <div id="wapper-iframe" className="wapper-iframe">
        {componentMap[gameType] &&
          React.createElement(componentMap[gameType], {
            config,
            gameId,
            identityNumber,
          })}
      </div>

      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}

      {error && (
        <ErrorPopup
          message={error}
          onClose={() => setError(null)}
          header="Error"
          btnText="Close"
          title=""
        />
      )}
    </div>
  );
}

export default App;
